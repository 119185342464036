import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormHidden = lazy(
  () => import("./form-hidden-CfUX_xHU.js").then((module) => ({
    default: module.FormHidden
  }))
);
function FormHidden({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormHidden, { ...props });
}
const useFormHiddenContent = () => {
  return useMemo(
    () => ({
      formHidden: {
        component: FormHidden
      }
    }),
    []
  );
};
export {
  LazyFormHidden as FormHidden,
  useFormHiddenContent
};
